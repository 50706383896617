<template>
    <div v-loading="$wait.is('loading.addresses')">
        <div class="flex justify-between border-b border-solid border-gray-200 pb-2 mb-5">
            <h1>{{ $t('clients.addresses') }}</h1>
            <router-link
                v-if="addressesData.addresses_count > 1"
                :to="{ name: 'client_addresses', params: { uuid: clientUuid } }"
                class="flex items-center cursor-pointer bg-red-300  hover:bg-red-400 text-white text-xs rounded-lg px-2 transition"
            >
                {{ $t('clients.show_all') }}
            </router-link>
        </div>

        <p>
            {{ addressesData.address.title }}
        </p>
        <div class="border bg-gray-100 rounded-lg p-1">
            <GoogleMap
                :height="150"
                :coords="{
                    map_lat: addressesData.address.map_lat,
                    map_lng: addressesData.address.map_lng,
                }"
                :geofenceRadius="addressesData.address.geofence_radius"
            />
        </div>

        <p class="mt-4">
            {{ addressesData.address.street }}, {{ addressesData.address.post_code }} {{ addressesData.address.city }}
        </p>
    </div>
</template>
<script>

export default {
    components: {
        GoogleMap: () => import(/* webpackChunkName: "GoogleMapForAddress" */ '@/components/GoogleMapForAddress/GoogleMapForAddress.vue'),
    },

    props: {
        addressesData: {
            type:    Object,
            default: () => ({
                address: {
                    map_lat: 56,
                    map_lng: 18,
                },
            }),
        },
    },

    data() {
        return {
            clientUuid: this.$route.params.uuid,
        };
    },
};
</script>
